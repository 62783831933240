import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tags", "integrity", "security", "leadership"]

  connect() {
    this.updateCheckboxes();
  }

  toggle(event) {
    const isChecked = event.target.checked;
    const tagsInput = this.tagsTarget;
    const labelText = event.target.parentElement.textContent.trim();
    const tags = this.trimmedTags();
    if (isChecked) {
      if(!tags.includes(labelText)) {
        tags.push(labelText);
        tagsInput.value = tags.join(", ");
      }
    } else {
      tagsInput.value = tags.filter(tag => tag !== labelText).join(", ");
    }
  }

  updateCheckboxes(){
    const tags = this.trimmedTags();

    if(tags.includes(this.integrityTarget.value.trim())){
      this.integrityTarget.checked = true;
    } else {
      this.integrityTarget.checked = false;
    };

    if(tags.includes(this.leadershipTarget.value.trim())){
      this.leadershipTarget.checked = true;
    } else {
      this.leadershipTarget.checked = false;
    };

    if(tags.includes(this.securityTarget.value.trim())){
      this.securityTarget.checked = true;
    } else {
      this.securityTarget.checked = false;
    };
  }

  trimmedTags(){
    return this.tagsTarget.value.split(",").map(tag => tag.trim()).filter(tag => tag !== "");
  }
}
