import "../controllers";

import Rails from "@rails/ujs";
import $ from "jquery";
import * as Survey from "survey-jquery";
import "survey-core/defaultV2.min.css";

if (!window._rails_loaded) {
  Rails.start();
}

window.$ = $;
window.jQuery = $;
window.Survey = Survey

document.addEventListener("DOMContentLoaded", () => {
  $('body.tasks #surveyContainer.ibes').contextmenu(function(e) {
    e.preventDefault();
    return false;
  });
});

// https://adrianroselli.com/2017/11/a-responsive-accessible-table.html
const ResponsiveCellHeaders = (elmID) => {
  try {
    var THarray = [];
    var table = document.getElementById(elmID);
    var ths = table.getElementsByTagName("th");
    for (var i = 0; i < ths.length; i++) {
      var headingText = ths[i].innerText.split("\n").filter(Boolean)[0].trim();
      THarray.push(headingText);
    }
    var styleElm = document.createElement("style"),
      styleSheet;
    document.head.appendChild(styleElm);
    styleSheet = styleElm.sheet;
    for (var i = 0; i < THarray.length; i++) {
      styleSheet.insertRule(
        "#" +
          elmID +
          " tbody td:nth-child(" +
          (i + 1) +
          ')::before {content:"' +
          THarray[i] +
          ': ";}',
        styleSheet.cssRules.length
      );
    }
  } catch (e) {
    console.log("ResponsiveCellHeaders(): " + e);
  }
}

window.ResponsiveCellHeaders = ResponsiveCellHeaders;

