// app/javascript/controllers/hello_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["target", "button"]

  connect() {
    document.addEventListener("click", this.handleClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this))
  }

  toggle() {
    this.targetTarget.classList.toggle("hidden")
  }

  handleClickOutside(event) {
    if (!this.targetTarget.contains(event.target) && !this.buttonTarget.contains(event.target) &&
        !this.targetTarget.classList.contains("hidden")) {
      this.targetTarget.classList.add("hidden")
    }
  }
}
